.head1_b {
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
}

.head1_sb {
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
}

.head0 {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
}

.head0_sb {
  font-weight: 400;
  font-size: 28px;
  line-height: 48px;
}

.new-head {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
}

.new-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
}

.head2_sb {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.head2_b {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.head2_m {
  font-weight: 500;

  font-size: 24px;
  line-height: 32px;
}

.subtitle1_m {
  font-weight: 500;

  font-size: 21px;
  line-height: 30px;
}

.subtitle1_sb {
  font-weight: 600;

  font-size: 21px;
  line-height: 30px;
}

.subtitle1_b {
  font-weight: 700;

  font-size: 21px;
  line-height: 30px;
}

.subtitle2_sb {
  font-weight: 600;

  font-size: 18px;
  line-height: 26px;
}

.subtitle2_b {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.subtitle3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

.subtitle4 {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.body1 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.body2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.body3_m {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.body3_r {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.button {
  font-weight: 600;

  font-size: 16px;
  line-height: 24px;
}

.button2 {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.caption1 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.temp-caption {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
}

.gray50 {
  color: var(--gray50);
}

.gray100 {
  color: var(--gray100);
}

.gray200 {
  color: var(--gray200);
}

.gray300 {
  color: var(--gray300);
}

.gray400 {
  color: var(--gray400);
}

.gray500 {
  color: var(--gray500);
}

.gray600 {
  color: var(--gray600);
}

.gray700 {
  color: var(--gray700);
}

.gray800 {
  color: var(--gray800);
}

.gray900 {
  color: var(--gray900);
}

.warmGray250 {
  color: var(--warmGray250);
}

.warmGray400 {
  color: var(--warmGray400);
}

.warmGray500 {
  color: var(--warmGray500);
}

.warmGray650 {
  color: var(--warmGray650);
}

.warmGray700 {
  color: var(--warmGray700);
}

.warmGray800 {
  color: var(--warmGray800);
}

.warmGray850 {
  color: var(--warmGray850);
}

.warmGray900 {
  color: var(--warmGray900);
}

.green250 {
  color: var(--green250);
}

.green350 {
  color: var(--green350);
}

.green400 {
  color: var(--green400);
}

.green450 {
  color: var(--green450);
}

.green500 {
  color: var(--green500);
}
.green600 {
  color: var(--green600);
}

.green800 {
  color: var(--green800);
}

.orange350 {
  color: var(--orange350);
}

.orange450 {
  color: var(--orange450);
}

.orange650 {
  color: var(--orange650);
}

.orange700 {
  color: var(--orange700);
}

.black {
  color: var(--black);
}

.white {
  color: var(--white);
}

.reviewYellow {
  color: var(--reviewYellow);
}

.danger {
  color: var(--danger);
}

.goreen {
  color: var(--goreen);
}

.goreen200 {
  color: var(--goreen200);
}

.blue50 {
  color: var(--blue50);
}

.blue700 {
  color: var(--blue700);
}

.brown50 {
  color: var(--brown50);
}

.brown250 {
  color: var(--brown250);
}

.brown300 {
  color: var(--brown300);
}

.brown450 {
  color: var(--brown450);
}

.brown600 {
  color: var(--brown600);
}

.brown650 {
  color: var(--brown650);
}

.brown700 {
  color: var(--brown700);
}

.underline {
  text-decoration: underline;
  text-underline-offset: 1px;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-700 {
  font-weight: 700;
}

.font-left {
  text-align: left;
}

.font-center {
  text-align: center;
}

.v-flex-grow {
  flex-grow: 1;
  width: 0;
}

.h-flex-grow {
  flex-grow: 1;
  height: 0;
}

.font-ellipsis {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.pre-wrap {
  white-space: pre-wrap;
}

.head_42_eb {
  font-size: 42px;
  font-weight: 800;
  line-height: 60px;
}

.head_42_b {
  font-size: 42px;
  font-weight: 700;
  line-height: 60px;
}

.head_42_sb {
  font-size: 42px;
  font-weight: 600;
  line-height: 60px;
}

.head_42_m {
  font-size: 42px;
  font-weight: 500;
  line-height: 60px;
}

.head_42_r {
  font-size: 42px;
  font-weight: 400;
  line-height: 60px;
}

.head_42_l {
  font-size: 42px;
  font-weight: 300;
  line-height: 60px;
}

.head_32_eb {
  font-size: 32px;
  font-weight: 800;
  line-height: 42px;
}

.head_32_b {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
}

.head_32_sb {
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
}

.head_32_m {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
}

.head_32_r {
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
}

.head_32_l {
  font-size: 32px;
  font-weight: 300;
  line-height: 42px;
}

.head_28_eb {
  font-size: 28px;
  font-weight: 800;
  line-height: 40px;
}

.head_28_b {
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
}

.head_28_sb {
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
}

.head_28_m {
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
}

.head_28_r {
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}

.head_28_l {
  font-size: 28px;
  font-weight: 300;
  line-height: 40px;
}

.head_24_eb {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
}

.head_24_b {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.head_24_sb {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.head_24_m {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.head_24_r {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.head_24_l {
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
}

.subtitle_21_eb {
  font-size: 21px;
  font-weight: 800;
  line-height: 30px;
}

.subtitle_21_b {
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
}

.subtitle_21_sb {
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
}

.subtitle_21_m {
  font-size: 21px;
  font-weight: 500;
  line-height: 30px;
}

.subtitle_21_r {
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
}

.subtitle_21_l {
  font-size: 21px;
  font-weight: 300;
  line-height: 30px;
}

.subtitle_18_eb {
  font-size: 18px;
  font-weight: 800;
  line-height: 26px;
}

.subtitle_18_b {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.subtitle_18_sb {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

.subtitle_16_eb {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}

.subtitle_16_b {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.subtitle_16_sb {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.subtitle_14_eb {
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
}

.subtitle_14_b {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.subtitle_14_sb {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.body_18_m {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.body_18_r {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.body_18_l {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

.body_16_m {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.body_16_r {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.body_16_l {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.body_14_m {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.body_14_r {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.body_14_l {
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}

.caption_12_b {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

.caption_12_sb {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.caption_12_m {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.caption_12_r {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.caption_11_b {
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
}

.caption_11_sb {
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
}

.caption_11_m {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
}

.caption_11_r {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
}

.button_16_sb {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

.button_14_sb {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

.button_12_sb {
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
}

.lh-42 {
  line-height: 42px;
}

.lh-40 {
  line-height: 40px;
}

.lh-32 {
  line-height: 32px;
}

.lh-30 {
  line-height: 30px;
}

.lh-28 {
  line-height: 28px;
}

.lh-26 {
  line-height: 26px;
}

.lh-24 {
  line-height: 24px;
}

.lh-22 {
  line-height: 22px;
}

.lh-20 {
  line-height: 20px;
}

.lh-18 {
  line-height: 18px;
}

@media screen and (min-width: 768px) {
  .md_head_42_eb {
    font-size: 42px;
    font-weight: 800;
    line-height: 60px;
  }

  .md_head_42_b {
    font-size: 42px;
    font-weight: 700;
    line-height: 60px;
  }

  .md_head_42_sb {
    font-size: 42px;
    font-weight: 600;
    line-height: 60px;
  }

  .md_head_42_m {
    font-size: 42px;
    font-weight: 500;
    line-height: 60px;
  }

  .md_head_42_r {
    font-size: 42px;
    font-weight: 400;
    line-height: 60px;
  }

  .md_head_42_l {
    font-size: 42px;
    font-weight: 300;
    line-height: 60px;
  }

  .md_head_32_eb {
    font-size: 32px;
    font-weight: 800;
    line-height: 42px;
  }

  .md_head_32_b {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
  }

  .md_head_32_sb {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
  }

  .md_head_32_m {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
  }

  .md_head_32_r {
    font-size: 32px;
    font-weight: 400;
    line-height: 42px;
  }

  .md_head_32_l {
    font-size: 32px;
    font-weight: 300;
    line-height: 42px;
  }

  .md_head_28_eb {
    font-size: 28px;
    font-weight: 800;
    line-height: 40px;
  }

  .md_head_28_b {
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
  }

  .md_head_28_sb {
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  .md_head_28_m {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
  }

  .md_head_28_r {
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
  }

  .md_head_28_l {
    font-size: 28px;
    font-weight: 300;
    line-height: 40px;
  }

  .md_head_24_eb {
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
  }

  .md_head_24_b {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .md_head_24_sb {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  .md_head_24_m {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  .md_head_24_r {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }

  .md_head_24_l {
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
  }

  .md_subtitle_21_eb {
    font-size: 21px;
    font-weight: 800;
    line-height: 30px;
  }

  .md_subtitle_21_b {
    font-size: 21px;
    font-weight: 700;
    line-height: 30px;
  }

  .md_subtitle_21_sb {
    font-size: 21px;
    font-weight: 600;
    line-height: 30px;
  }

  .md_subtitle_21_m {
    font-size: 21px;
    font-weight: 500;
    line-height: 30px;
  }

  .md_subtitle_21_r {
    font-size: 21px;
    font-weight: 400;
    line-height: 30px;
  }

  .md_subtitle_21_l {
    font-size: 21px;
    font-weight: 300;
    line-height: 30px;
  }

  .md_subtitle_18_eb {
    font-size: 18px;
    font-weight: 800;
    line-height: 26px;
  }

  .md_subtitle_18_b {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
  }

  .md_subtitle_18_sb {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  }

  .md_subtitle_16_eb {
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
  }

  .md_subtitle_16_b {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .md_subtitle_16_sb {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .md_subtitle_14_eb {
    font-size: 14px;
    font-weight: 800;
    line-height: 22px;
  }

  .md_subtitle_14_b {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }

  .md_subtitle_14_sb {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }

  .md_body_18_m {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }

  .md_body_18_r {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  .md_body_18_l {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }

  .md_body_16_m {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .md_body_16_r {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .md_body_16_l {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  .md_body_14_m {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  .md_body_14_r {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .md_body_14_l {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
  }

  .md_caption_12_b {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }

  .md_caption_12_sb {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
  }

  .md_caption_12_m {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }

  .md_caption_12_r {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .md_caption_11_b {
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
  }

  .md_caption_11_sb {
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
  }

  .md_caption_11_m {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
  }

  .md_caption_11_r {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
  }

  .md_button_16_sb {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .md_button_14_sb {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
  }

  .md_button_12_sb {
    font-size: 14px;
    font-weight: 600;
    line-height: 12px;
  }
}

@media screen and (min-width: 1024px) {
  .lg_head_28_b {
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
  }

  .lg_head_42_b {
    font-size: 42px;
    font-weight: 700;
    line-height: 60px;
  }

  .lg_head_24_b {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .lg_head_24_r {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }

  .lg_body_14_m {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  .lg_body_14_r {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .lg_body_16_r {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .lg_body_16_m {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .lg_body_18_r {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  .lg_body_18_m {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }

  .lg_head_28_m {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
  }

  .lg_head_28_sb {
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  .lg_head_32_b {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
  }

  .lg_subtitle_18_r {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }

  .lg_subtitle_18_sb {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  }

  .lg_subtitle_21_r {
    font-size: 21px;
    font-weight: 400;
    line-height: 30px;
  }

  .lg_subtitle_21_b {
    font-size: 21px;
    font-weight: 700;
    line-height: 30px;
  }

  .lg_head_48_r {
    font-size: 48px;
    font-weight: 400;
    line-height: 60px;
  }
}
